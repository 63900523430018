.main-box {
  display: flex;
  flex-direction: column;
}

.main-bar {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1em;
}

.right-content {
  padding: 0.1em;
}

.center-content {
  padding: 0.1em;
}

.left-content {
  padding: 0.1em;
}
